<template>
  <div>
    <f-page-header>
      <h1>{{ $t('labels.privacy') }}</h1>
    </f-page-header>
    <f-container topBottom>
      Para continuar utilizando o sistema É necessário aceitar nossa politica de
      privacidade. <br />
      <br />
      <v-card elevation="0">
        <v-card-text>
          <div class="p-wrapper">
            <f-privacy-text />
          </div>
        </v-card-text>
      </v-card>
      <br />
      <v-btn color="primary" large elevation="0" @click="save">
        {{ $t('labels.accept') }}
      </v-btn>
    </f-container>
  </div>
</template>

<script>
import FPrivacyText from '../../components/PrivacyPolicyText.vue';

export default {
  components: {
    FPrivacyText,
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      this.$store.commit('user/setID', this.$store.getters['auth/user'].id);
      this.$store.dispatch('user/get');
    },
    async save() {
      await this.$store.dispatch('user/accept');
      this.$store.state.system.isLocked = false;

      this.$router.push({
        name: 'profile-data',
      });
    },
  },
};
</script>

<style lang="css">
.p-wrapper {
  height: 300px;
  overflow-y: auto;
}
</style>
